var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label:
                      _vm.$t(
                        "modals.createPersonalizationSet.microsoft.chooseMarketingList"
                      ) + "*"
                  }
                },
                [
                  _c("b-form-select", {
                    attrs: { options: _vm.marketingListsOptions },
                    model: {
                      value: _vm.marketingList,
                      callback: function($$v) {
                        _vm.marketingList = $$v
                      },
                      expression: "marketingList"
                    }
                  })
                ],
                1
              ),
              _c("p", { staticClass: "font-italic" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "modals.createPersonalizationSet.microsoft.chooseMarketingListDesc"
                      )
                    ) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "mr-auto", attrs: { cols: "auto" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "green-button border-0 mt-2",
                  attrs: { size: "lg" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("prev-step")
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("modals.createPersonalizationSet.back")) +
                      " "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "green-button border-0 mt-2",
                  attrs: {
                    size: "lg",
                    type: "submit",
                    disabled: _vm.marketingList === null
                  },
                  on: { click: _vm.next }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("modals.createPersonalizationSet.next")) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }