<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-form-group
          :label="$t('modals.createPersonalizationSet.microsoft.chooseMarketingList') + '*'"
        >
          <b-form-select
            v-model="marketingList"
            :options="marketingListsOptions"
          ></b-form-select>
        </b-form-group>
        <p class="font-italic">
          {{ $t('modals.createPersonalizationSet.microsoft.chooseMarketingListDesc') }}
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="auto" class="mr-auto">
        <b-button
          size="lg"
          @click="$emit('prev-step')"
          class="green-button border-0 mt-2"
        >
          {{ $t('modals.createPersonalizationSet.back') }}
        </b-button>
      </b-col>
      <b-col cols="auto">
        <b-button
          size="lg"
          type="submit"
          class="green-button border-0 mt-2"
          :disabled="marketingList === null"
          @click="next"
        >
          {{ $t('modals.createPersonalizationSet.next') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import GetMicrosoftCRMMarketingLists from '@/graphQlQueries/queries/getMicrosoftCRMMarketingLists';
import SetLoadingIndicator from '@/graphQlQueries/mutations/setLoadingIndicatorInCache';

export default {
  name: 'PersonalizationMicrosoftStepTwo',
  props: {
    marketingListIdProp: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    marketingList: null,
    marketingListsOptions: [],
  }),
  apollo: {
    marketingListsOptions: {
      query: GetMicrosoftCRMMarketingLists,
      update(data) {
        this.$apollo.mutate({
          mutation: SetLoadingIndicator,
          variables: {
            isIndicatorLoading: false,
          },
        });
        if (data.getMicrosoftCRMMarketingLists.length === 0) {
          return [{ value: null, text: 'No results)' }];
        }
        const marketingList = data.getMicrosoftCRMMarketingLists.map((item) => ({
          value: item.marketing_list_id,
          text: item.marketing_list_name,
        }));
        marketingList.sort((a, b) => a.text.localeCompare(b.text));
        return [
          { value: null, text: 'Select a marketing list' },
          ...marketingList,
        ];
      },
    },
  },
  async created() {
    if (this.marketingListIdProp) {
      this.marketingList = this.marketingListIdProp;
    }
    await this.$apollo.mutate({
      mutation: SetLoadingIndicator,
      variables: {
        isIndicatorLoading: true,
      },
    });
  },
  methods: {
    async next() {
      await this.$apollo.mutate({
        mutation: SetLoadingIndicator,
        variables: {
          isIndicatorLoading: true,
        },
      });
      this.$emit('next-step', this.marketingList, this.marketingListsOptions);
    },
  },
};
</script>

<style scoped lang="scss">
.modal-body h4 {
  font-size: 15px;
  font-weight: bold;
  color: #44484C;
}

ul {
  padding-left: 0;
  list-style: none;

  li {
    color: #44484C;

    /deep/ svg {
      color: #B9B9B9;

      &.active {
        color: #53DC86;
      }
    }

    &.past-step {
      cursor: pointer;
    }
  }
}

div.step-1 {
  min-height: 120px;
}

.grey-line {
  height: 30px;
  border-left: 1px solid #B9B9B9;
  margin-left: 6px;
}

.show-url-icon svg {
  font-size: 60px;
}

.show-url {
  font-size: 1.1rem;
}

.loading-spinner {
  min-height: 400px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-spinner {
  position: absolute;
  inset: 0px;
  opacity: 0.85;
  backdrop-filter: blur(2px);
  width: 100%;
  height: 100%;
  background-color: #f8f9fa;
}
.disabled {
  cursor: not-allowed;
}
</style>
