import gql from 'graphql-tag';

export default gql`
query getMicrosoftCRMMarketingLists {
  getMicrosoftCRMMarketingLists {
    marketing_list_id
    marketing_list_name
  }
}
`;
